@import url("https://fonts.googleapis.com/css2?family=Fira+Sans&display=swap");

:root {
    --black: #000;
    --primary: #d63031;
}

::-webkit-scrollbar {
    background: transparent;
    width: 5px;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Fira Sans", sans-serif;
}

body {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #bdc3c7;
}

a {
    text-decoration: none;
}

#root {
    width: 400px;
    overflow-x: hidden;
    background: #ecf0f1;
    max-width: 100%;
    min-height: 100vh;
    position: relative;
}

.Login .container {
    width: 100%;
    height: 180px;
    border-radius: 0px 0px 25px 25px;
    background-color: var(--primary);
    padding: 20px;
}

.Login .title {
    font-size: 18px;
    font-weight: 500;
    color: #fff;
    margin-top: 10px;
}

.Login .text {
    font-size: 14px;
    font-weight: 400;
    color: #f3f3f3;
    margin-top: 10px;
}

.Login .input-container {
    width: 100%;
    justify-content: center;
    text-align: center;
    flex-direction: row;
    display: flex;
    margin-top: 50px;
}

.Login .input {
    font-size: 26px;
    background: transparent;
    border: none;
    outline: none;
    width: 290px;
    height: 70px;
    border-radius: 0px 10px 10px 0px;
    box-shadow: 5px 4px 4px 0px #0000001a;
    padding: 0px 20px;
    letter-spacing: 2px;
    color: #000;
    background-color: #fff;
}

.Login .input::placeholder {
    font-size: 18px;
    letter-spacing: normal;
    color: #00000099;
    opacity: 1;
}

.Login .mobile-icon {
    width: 25px;
    height: 41.67px;
}

.Login .mobile-num {
    background-color: var(--primary);
    width: 70px;
    height: 70px;
    border-radius: 10px 0px 0px 10px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Login .btn {
    background-color: var(--primary);
    border-radius: 10px;
    font-size: 16px;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 25px;
}

.Login .mobile {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Login .otp-input {
    width: 50px !important;
    height: 50px;
    justify-content: center;
    align-items: center;
    margin: 0px 5px;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #fff;
    color: #000;
    border: none;
    font-size: 30px;
    box-shadow: 5px 4px 4px 0px #0000001a;
    outline: none;
}

.Login .otp-input:focus {
    border: 2px solid #d63031;
}

.Login .logo {
    width: 120px;
}

.Login .logo-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.Login .user-ref {
    width: 100%;
    padding: 0px 20px;
    margin-top: 20px;
}

.Login .refer {
    width: 100%;
    height: 40px;
    padding: 0px 10px;
    border: none;
    box-shadow: 5px 4px 4px 0px #0000001a;
    border-radius: 10px;
    outline: none;
    font-size: 15px;
}

/* ===========================Add Amount============================== */

.AddAmount .container {
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
}

.AddAmount .qr-img {
    width: 250px;
    height: 250px;
    margin-top: 20px;
}

.AddAmount .input {
    font-size: 15px;
    background: transparent;
    border: none;
    outline: none;
    width: 300px;
    height: 40px;
    border-radius: 10px;
    box-shadow: 5px 4px 4px 0px #0000001a;
    padding: 0px 20px;
    color: #000;
    margin-top: 20px;
    background-color: #fff;
}

.AddAmount .btn {
    background-color: var(--primary);
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 25px;
}

.AddAmount .text {
    text-align: center;
    font-size: 14px;
    margin-top: 20px;
}

.AddAmount .upi-id {
    font-size: 16px;
    margin-top: 15px;
    text-align: center;
}

.AddAmount .copy {
    background-color: #d63031;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
    margin-top: 15px;
    cursor: pointer;
    font-size: 14px;
}

.AddAmount .title {
    font-size: 20px;
    font-weight: 600;
}

/* =========================Withdraw========================== */

.Withdraw .container {
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
    background: #ecf0f1;
}

.Withdraw .title {
    font-size: 20px;
    font-weight: 600;
}

.Withdraw .balance {
    border-radius: 10px;
    border: 1px solid #d63031;
    padding: 10px;
    width: 150px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.Withdraw .wallet-text {
    font-size: 13px;
    font-weight: 500;
}

.Withdraw .wallet-amount {
    color: #d63031;
    font-size: 20px;
    font-weight: 600;
    margin-top: 5px;
}

.Withdraw .input {
    font-size: 15px;
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 40px;
    border-radius: 10px;
    box-shadow: 5px 4px 4px 0px #0000001a;
    padding: 0px 20px;
    color: #000;
    margin-top: 20px;
    background-color: #fff;
}

.Withdraw .btn {
    background-color: var(--primary);
    border-radius: 10px;
    font-size: 14px;
    color: #fff;
    padding: 10px 20px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 25px;
}

.Withdraw .amount {
    background-color: var(--primary);
    width: 90px;
    border-radius: 10px;
    padding: 7px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
}

.Withdraw .tab-amount {
    margin: 5px;
    cursor: pointer;
}

.Withdraw .tab {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

/* ======================WalletHistory======================== */

.WalletHistory .container {
    padding: 20px;
    /* justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex; */
}

.WalletHistory .title {
    font-size: 20px;
    font-weight: 600;
}

.WalletHistory .balance {
    border-radius: 10px;
    border: 1px solid #d63031;
    padding: 10px;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.WalletHistory .wallet-text {
    font-size: 15px;
    font-weight: 500;
}

.WalletHistory .wallet-amount {
    color: #d63031;
    font-size: 20px;
    font-weight: 600;
}

.WalletHistory .tab-bar {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
}

.WalletHistory .indexActive {
    align-items: center;
    text-align: center;
    border-bottom: 2px solid #d63031;
    padding-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    width: calc(100% - 50%);
}

.WalletHistory .tabName {
    width: 100%;
    align-items: center;
    text-align: center;
    cursor: pointer;
}

.WalletHistory .withdrawal-status {
    box-shadow: 6px 5px 5px 0px #0000001a;
    width: 100%;
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    /* border: 1px solid #d63031; */
    border-radius: 10px;
    background-color: #fff;
}

.WalletHistory .recharge-ruppes {
    font-size: 14px;
    font-weight: 500;
}

.WalletHistory .recharge-status {
    text-align: center;
}

.WalletHistory .status {
    font-size: 12px;
    font-weight: 500;
}

.WalletHistory .process {
    font-size: 12px;
    font-weight: 500;
    color: #d63031;
    margin-left: 5px;
}

.WalletHistory .date-time {
    font-size: 12px;
    font-weight: 500;
}

.WalletHistory .status-process {
    display: flex;
    margin-top: 3px;
}

/* ========================   Home  ======================== */

.Home .home-logo {
    width: 100%;
    height: 50px;
    background-color: var(--primary);
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 0px 20px;
}

.Home .menu-icon {
    width: 30px;
    height: 20px;
}

.Home .wallet-icon {
    width: 15px;
    height: 15px;
}

.Home .user-wallet {
    display: flex;
    align-items: center;
    background-color: #b41605;
    padding: 5px 15px;
    border-radius: 100px;
}

.Home .wallet-amount {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.01em;
    color: #fff;
    margin-left: 5px;
}

.Home .logo {
    width: 70px;
}

.Home .container {
    padding: 20px;
}

.Home .user-num {
    background-color: var(--primary);
    color: #fff;
    border-radius: 15px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Home .period-num {
    text-align: center;
}

.Home .period {
    display: flex;
    align-items: center;
}

.Home .trophy-icon {
    width: 10px;
    height: 10px;
}

.Home .text {
    font-size: 10px;
    font-weight: 500;
    color: #f3f3f3;
    margin-left: 4px;
}

.Home .number {
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;
    letter-spacing: 0.5px;
}

.Home .time {
    font-size: 14px;
    font-weight: 500;
    color: #f3f3f3;
    margin-bottom: 3px;
}

.Home .time-num {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    background-color: #f3f3f3;
    border-radius: 0px 10px 0px 10px;
    width: 75px;
    height: 40px;
    overflow: hidden;
    letter-spacing: 1px;
    display: grid;
    place-items: center;
}

.Home .color-bat {
    box-shadow: 0px 0px 4px 4px #0000000d;
    background-color: #ffffff;
    border-radius: 15px;
    margin-top: 15px;
    color: #fff;
    position: relative;
    width: 100%;
}

.Home .cover-timer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 60px;
    color: #fff;
    letter-spacing: 3px;
    z-index: 10;
    border-radius: 15px;
}

.Home .three-color {
    border-bottom: 2px solid #0000001a;
}

.Home .colors {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px;
}

.Home .color-green {
    background-color: #2ecc71;
    padding: 8px 20px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 0px 13px 0px 13px;
}

.Home .color-violet {
    background-color: #d96bff;
    padding: 8px 20px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 8px;
}

.Home .color-red {
    background-color: #e74c3c;
    padding: 8px 27px;
    font-size: 20px;
    font-weight: 500;
    border-radius: 0px 13px 0px 13px;
}

.Home .box {
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 15px;
}

.Home .color-box {
    background-color: #e74c3c;
    width: 50px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    border-radius: 10px;
    margin: 4px;
    cursor: pointer;
}

.Home .color-num {
    font-size: 25px;
    font-weight: 500;
}

.Home .tab-bar {
    display: flex;
    margin-top: 15px;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    padding: 10px 0px 0px 0px;
    border-radius: 5px;
}

.Home .indexActive {
    align-items: center;
    border-bottom: 2px solid #d63031;
    padding-bottom: 4px;
    font-size: 14px;
    font-weight: 600;
    color: #d63031;
    justify-content: center;
    display: flex;
}

.Home .tabName {
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 5px;
}

.Home .game-history {
    background-color: #fff;
    margin-top: 10px;
    border-radius: 15px;
}

.Home .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: #d63031;
    border-radius: 15px 15px 0px 0px;
}

.Home .result-box {
    color: #fff;
    font-size: 14px;
    font-weight: 500;
}

.Home .result-user {
    padding-bottom: 5px;
}

.Home .result {
    padding: 10px 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.Home .result-id {
    font-size: 14px;
    font-weight: 500;
    width: 33.33%;
    text-align: center;
}

.Home .result-num {
    font-size: 14px;
    font-weight: 500;
    width: 33.33%;
    text-align: center;
}

.Home .result-color {
    width: 15px;
    height: 15px;
    background-color: #2ecc71;
    border-radius: 100%;
}

.Home .load {
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 20px;
}

.Home .load-btn {
    font-size: 14px;
    font-weight: 500;
    color: #d63031;
    border: 1px solid #d63031;
    padding: 6px 70px;
    border-radius: 6px;
    cursor: pointer;
}

.Home .win-user {
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px #0000000d;
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    margin: 10px 0px;
    margin-bottom: 5px;
}

.Home .user-tokan {
    font-size: 14px;
    font-weight: 600;
}

.Home .win-ruppes {
    font-size: 14px;
    font-weight: 600;
    color: #2ecc71;
}

.Home .pendind-ruppes {
    font-size: 14px;
    font-weight: 600;
    color: #9b59b6;
}

.Home .loss-ruppes {
    font-size: 14px;
    font-weight: 600;
    color: #d63031;
}

.Home .user-history {
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px #0000000d;
    padding: 5px 15px;
    border-radius: 8px;
    margin-bottom: 15px;
}

.Home .user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 0px;
}

.Home .tokan {
    font-size: 16px;
    font-weight: 600;
}

.Home .ruppes {
    font-size: 16px;
    font-weight: 600;
    color: #2ecc71;
}

.Home .user-bet {
    font-size: 13px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin: 5px 0px;
}

.Home .select-num {
    display: flex;
    align-items: center;
}

.Home .history-text {
    font-size: 12px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
}

.Home .select-color {
    font-size: 15px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    margin: 0px 5px;
}

.Home .select-amount {
    width: 100%;
    height: 100vh;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    z-index: 99;
    max-width: 400px;
    transform: translate(0%, 0);
}

.Home .popup {
    background-color: #ecf0f1;
    width: calc(100% - 40px);
    border-radius: 15px;
    /* margin-top: auto; */
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    overflow: hidden;
}

.Home .win-go {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
}

.Home .select-num-color {
    font-size: 13px;
    font-weight: 500;
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px #0000000d;
    padding: 10px 100px;
    color: #000;
    border-radius: 5px;
}

.Home .balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    padding: 0px 20px;
}

.Home .balance-add {
    display: flex;
    align-items: center;
}

.Home .balance-num {
    background-color: #fff;
    box-shadow: 0px 0px 4px 4px #0000000d;
    padding: 5px 10px;
    margin: 0px 2px;
}

.Home .balance-num.active {
    color: #fff;
    background: var(--primary);
}

.Home .balance-text {
    font-size: 15px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
}

.Home .minus {
    padding: 1px 10px;
    background-color: #fff;
    font-size: 25px;
    box-shadow: 0px 0px 4px 4px #0000000d;
}

.Home .input-num {
    width: 70px;
    height: 32px;
    background-color: #fff;
    border: none;
    outline: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0px 5px;
}

.Home .buttons {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.Home .cancel {
    font-size: 14px;
    font-weight: 600;
    background-color: #fff;
    padding: 10px;
    width: 50%;
    text-align: center;
}

.Home .total {
    font-size: 14px;
    background-color: #d63031;
    color: #fff;
    padding: 10px;
    letter-spacing: 0.5px;
    text-align: center;
    width: 50%;
}

.Home .menu {
    background-color: #d63031;
    width: 250px;
    height: 100%;
    min-height: 100vh;
    border-radius: 0px 30px 30px 0px;
    padding: 20px 0;
    position: absolute;
    left: -300px;
    top: 0;
    z-index: 999;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    transition: left 300ms linear;
}

.Home .cancel-icon {
    width: 30px;
    height: 30px;
}

.Home .abc {
    color: #8e44ad;
}

.Home .menu-close {
    justify-content: flex-end;
    display: flex;
    /* padding: 0px 15px; */
}

.Home .game {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ffffff80;
    padding: 15px 20px;
    color: #fff;
}

.Home .game-icon {
    width: 20px;
    height: 18px;
}

.Home .refer-icon {
    width: 18px;
    height: 11px;
}

.Home .menu-text {
    font-size: 14px;
    font-weight: 500;
    margin-left: 15px;
}

.Home .logout-btn {
    margin: 20px;
    width: 150px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #ffffff;
    padding: 10px 20px;
}

.Home .logout-text {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
}

.Home .cover {
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
}

.s-admin .colors {
    display: flex;
    padding: 0 10px;
}

.s-admin .color {
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
}

.s-admin .nums {
    flex-wrap: wrap;
    padding: 0 10px;
}

.s-admin .num {
    cursor: pointer;
    color: #fff;
    width: 60px;
    margin: 5px;
}

.s-admin .head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 30px;
    font-size: 16px;
    align-items: center;
    letter-spacing: 0.5px;
}

.s-admin .timer {
    font-size: 30px;
    letter-spacing: 1px;
}

.tel {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background: #060e24;
}

.tel .logo {
    background: var(--primary);
    width: calc(100% - 40px);
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 30px;
}

.tel .join {
    font-size: 16px;
    padding: 10px 30px;
    cursor: pointer;
    border: none;
    outline: none;
    background: #0870e2;
    color: #fff;
    letter-spacing: 0.5px;
    border-radius: 100px;
    display: flex;
    margin-bottom: 200px;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.tel .join img {
    width: 25px;
    margin-right: 8px;
}

.tel video {
    height: calc(100vh - 150px);
    margin-bottom: 20px;
}

.Home .markets {
    display: flex;
    max-width: 100%;
    overflow-x: auto;
    margin-bottom: 15px;
}

.Home .markets .market {
    padding: 7px 0;
    background: #fff;
    margin: 0 5px;
    width: 80px;
    text-align: center;
    border-radius: 5px;
}

.Home .markets .market.active {
    background: var(--primary);
    color: #fff;
}

.Home .user-pro {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    justify-content: space-between;
}

.Home .user {
    display: flex;
    align-items: center;
}

.Home .user-img {
    background-color: #fff;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Home .user-icon {
    width: 18px;
    height: 18px;
}

.Home .user-name {
    margin-left: 5px;
}

.Home .name {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
}

.Home .user-mo {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    margin-top: 1px;
}

.Home .login {
    text-align: center;
    border-radius: 6px;
    border: 1px solid #ffffff;
    padding: 7px 20px;
    margin-left: 10px;
}

.Reffer .container {
    padding: 20px;
    justify-content: center;
    flex-direction: column;
    display: flex;
}

.Reffer .title {
    font-size: 20px;
    font-weight: 600;
}

.Reffer .bonus {
    width: 100%;
    background-color: var(--primary);
    color: #fff;
    border-radius: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 0px 15px;
    box-shadow: 5px 4px 4px 0px #0000001a;
}

.Reffer .total-bonus {
    font-size: 15px;
    font-weight: 500;
}

.Reffer .ruppes {
    font-size: 15px;
    font-weight: 500;
    margin-top: 4px;
}

.Reffer .total-user {
    font-size: 12px;
    font-weight: 500;
    margin: 5px;
    background-color: var(--primary);
    width: 100px;
    padding: 5px 0px;
    text-align: center;
    color: #fff;
    border-radius: 5px;
}

.Reffer .gift {
    width: 50px;
    height: 50px;
    margin-top: 10px;
}

.Reffer .reffer {
    background-color: var(--primary);
    border-radius: 10px;
    margin-top: 20px;
}

.Reffer .code {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 15px;
}

.Reffer .copy-share {
    display: flex;
    align-items: center;
    margin-top: 5px;
}

.Reffer .link {
    color: #fff;
    font-size: 13px;
    font-weight: 500;
    margin-top: 5px;
}

.Reffer .copy {
    font-size: 12px;
    font-weight: 500;
    margin: 3px 5px;
    background-color: var(--primary);
    color: #fff;
    width: 50px;
    text-align: center;
    border-radius: 5px;
    padding: 5px 0px;
}

.Reffer .commision {
    background-color: var(--primary);
    text-align: center;
    padding: 10px;
}

.Reffer .text {
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    letter-spacing: 1px;
}

.Reffer .records {
    background-color: #fff;
    box-shadow: 5px 4px 4px 0px #0000001a;
    margin: 0px 20px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    padding: 0px 0px 0px 10px;
}

.Reffer .time {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary);
    width: calc(100% - 40%);
}

.Reffer .mobile-num {
    font-size: 13px;
    font-weight: 600;
    color: var(--primary);
    width: calc(100% - 50%);
}

.Reffer .recharge {
    font-size: 12px;
    font-weight: 600;
    background-color: var(--primary);
    padding: 8px 0px;
    color: #fff;
    width: 100px;
    text-align: center;
}

.Reffer .user-records {
    margin-top: 20px;
}
